import React from 'react';

export const ptStr = (points) => {
    var bet = [...`${points}`].reverse();
    var betTxt = [];
    for (let i = 0; i < bet.length; i++) {
        if (i % 3 === 0 && i > 0) {
            betTxt.push(`'`);
        }
        betTxt.push(`${bet[i]}`);
    }
    
    return betTxt.reverse().join(``);
}

const Points = ({points, ...props}) => {
    return <span {...props}>{ptStr(points)}</span>;
}

export default Points;
