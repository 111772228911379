const menu = [
    {
        key: `league`,
        title: `Zolmaņu līga`,
    },
    {
        key: `tourney`,
        title: `Turnīri`,
        sub: [
            {
                key: `view`,
                title: `Turnīri`,
            },
            {
                key: `previous`,
                title: `Iepriekšējie`,
            },
        ],
    },
    {
        key: `shop`,
        title: `Papildināt ZD`,
        sub: [
            {
                key: `subscribe`,
                title: `Zolmaņu līgas biedra abonoments`,
            },
            {
                key: `buy`,
                title: `Papildināt ZD`,
            },
            {
                key: `get`,
                title: `Saņemt ZD`,
            },
        ],
    },
    {
        key: `statistics`,
        title: `Statistika`,
        sub: [
            {
                key: `my`,
                title: `Mana statistika`,
            },
            {
                key: `global`,
                title: `Kopvērtējums`,
            },
        ],
    },
    {
        key: `achievements`,
        title: `Sasniegumi`,
    },
    {
        key: `profile`,
        title: `Mans profils`,
        sub: [
            {
                key: `about`,
                title: `Par zolīti`,
            },
            {
                key: `global`,
                title: `Spēļu apzīmējumi`,
            },
            {
                key: `start-of-game`,
                title: `Spēles uzsākšana`,
            },
            {
                key: `playtime`,
                title: `Spēlēšana`,
            },
            {
                key: `league`,
                title: `Zolmaņu līga`,
            },
            {
                key: `tournaments`,
                title: `Turnīri`,
            },
            {
                key: `rules-of-game`,
                title: `Zolītes noteikumi`,
            },
            {
                key: `regulations`,
                title: `Tiešsaistes zolītes turnīru nolikums`,
            },
        ],
    },
    // {
    //     key: `rules`,
    //     title: `Noteikumi`,
    //     sub: [
    //         {
    //             key: `website-rules`,
    //             title: `Mājas lapas lietošanas noteikumi`,
    //         },
    //         {
    //             key: `privacy`,
    //             title: `Privātuma noteikumi`,
    //         },
    //         {
    //             key: `cookies`,
    //             title: `Sīkdatņu noteikumi`,
    //         },
    //         {
    //             key: `data-process`,
    //             title: `Datu apstrādes noteikumi`,
    //         },
    //         {
    //             key: `distance`,
    //             title: `Distances līgums`,
    //         },
    //         {
    //             key: `subscription`,
    //             title: `Abonementa noteikumi`,
    //         },
    //     ],
    // },
    // {
    //     key: `about`,
    //     title: `Par mums`,
    //     sub: [
    //         {
    //             key: `contacts`,
    //             title: `Kontakti`,
    //         },
    //         {
    //             key: `philosophy`,
    //             title: `Filozofija`,
    //         },
    //         {
    //             key: `vision`,
    //             title: `Vīzija`,
    //         },
    //         {
    //             key: `mission`,
    //             title: `Misija`,
    //         },
    //         {
    //             key: `values`,
    //             title: `Vērtības`,
    //         },
    //     ],
    // },
    // {
    //     key: `cooperation`,
    //     title: `Sadarbība`,
    //     sub: [
    //         {
    //             key: `online-tournaments`,
    //             title: `Tiešsaistes turnīru organizēšana`,
    //         },
    //         {
    //             key: `live-tournaments`,
    //             title: `Klātienes turnīru organizēšana`,
    //         },
    //         {
    //             key: `addvertisement`,
    //             title: `Reklāmas noteikumi`,
    //         },
    //         {
    //             key: `website-statistics`,
    //             title: `Mājas lapas statistika`,
    //         },
    //     ],
    // },
];

export default menu;