import React from 'react';

const Statistic = ({achievement, ...props}) => {
    const acv = achievement.key.split(`-`);
    let width = `60%`;
    let textColor = `#cc9f01`;
    let marginBottom = `-30px`;
    if (acv[0] === `dailyGames`) {
        width = `50%`;
        textColor = `#8a30bf`;
    }


    return (
        <div style={{
            // border: `1px solid lime`,
            minHeight: 200,
            textAlign: `center`,
            display: `flex`,
            verticalAlign: `center`,
            flexDirection: `column`,
        }}>
            <div
                style={{
                    color: textColor,
                    marginBottom,
                    fontSize: `2.3em`,
                    fontWeight: 400,
                }}
            >
                {acv[1] ?? ` `}
            </div>
            <img
                src={require(`../../resources/statistics/${acv[0]}.svg`)}
                alt={achievement.key}
                style={{
                    width,
                    margin: `auto`
                }}
            />
        </div>
    )
}

export default Statistic;
