import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Table, Typography } from 'antd';
import React, { useContext } from 'react';
import Layout from '../engine/layout';
import moment from 'moment';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Statistic from '../engine/widgets/Statistic';
import { AppContext } from '../App';

const { Link } = Typography;

const AchievementsWrapper = (props) => {
    const app = useContext(AppContext);

    return <Achievements app={app} {...props} />;
}

class Achievements extends Layout {
    constructor(props) {
        super(props);
        this.app = props.app;
    }

    content() {
        const { width } = this.app.state.client.window.size;
        if (width > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    componentDidMount() {
        this.app = this.props.app;
    }

    componentDidUpdate() {
        this.app = this.props.app;
    }

    head() {
        return null;
    }

    contentMobile() {
        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Sasniegumi
                </Typography.Title>
            </>
        );
    }

    contentWide() {
        const { user } = this.app.state;
        
        const screen = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
            ratio: document.body.offsetWidth / document.body.offsetHeight,
        };
        
        let colPerRow = 6;
        if (screen.width <= 330) {
            colPerRow = 1;
        } else if (screen.width <= 400) {
            colPerRow = 2;
        } else if (screen.width <= 550) {
            colPerRow = 3;
        } else if (screen.width <= 850) {
            colPerRow = 4;
        }

        const rows = [];
        for (let col = 0, row = 0, i = 0; i < user.achievements.length; i++) {
            if (col === 0) {
                rows[row] = [];
            }

            rows[row][col] = user.achievements[i];
            if (++col === colPerRow) {
                row++;
                col = 0;
            }
        }

        // console.log(rows);

        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Sasniegumi
                </Typography.Title>
                <br />
                {
                    rows.map(row => (
                        <Row>
                            {
                                row.map(col => (
                                    <Col span={24 / colPerRow} title={col.description} style={{ filter: !col.achieved_at ? `grayscale(1)` : undefined }}>
                                        <Statistic achievement={col} />
                                    </Col>
                                ))
                            }
                        </Row>
                    ))
                }
            </>
        );
    }
}

export default AchievementsWrapper;
