import React, { Component } from 'react';

function Dots({ count }) {
    let result = [];

    for (let i = 0; i < count; i++) {
        result.push(
            <div
                style={{
                    top: 0,
                    width: 7,
                    height: 7,
                    borderRadius: 5,
                    position: `absolute`,
                    left: 5 + (5 + 7) * i,
                    background: `#B9B9B9`,
                    animation: `pulsate-${count} ${count / 3}s infinite ${i / 3}s`,
                }}
            />
        );
    }

    return <>{result}</>;
}

class LoadingDots extends Component {
    render() {
        let { count, style } = this.props;

        if (!count) {
            return <></>;
        }

        if (style === undefined) {
            style = {};
        }

        return (
            <div style={{
                position: `relative`,
                ...style,
                width: 5 * (count + 1) + 7 * count,
                height: 7,
            }}>
                <style>
                    {`@keyframes pulsate-${count} {
                        0% {
                            transform: scale(100%, 100%);
                        }
                        ${100 / count}% {
                            transform: scale(120%, 120%);
                        }
                        100% {
                            transform: scale(100%, 100%);
                        }
                    }`}
                </style>
                <Dots count={count} />
            </div>
        );
    }
}

export default LoadingDots;
