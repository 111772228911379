import { Checkbox, Dropdown, Menu, Select } from 'antd';
import React, { Component } from 'react';

import arrow from '../../resources/arrowDown.svg';
import Points from './Points';

class CreateRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: `P`,
            bet: 1000,
            minGames: 1,
            isLocked: false,
            isFast: true,
            is4Player: false,
        };
    }

    set = (key, value) => {
        const newState = { ...this.state };
        newState[key] = value;

        if (this.props.callback) {
            this.props.callback({ ...newState });
        }

        this.setState({ ...newState });
    };

    render() {
        const types = [`P`, `G`, `PM`, `GM`];
        const bets = [0, 1000, 3000, 5000, 10000, 30000, 50000, 100000, 500000, 1000000];
        const games = [];
        for (let i = 0; i < 28; ++i) {
            games.push(i + 1);
        }

        return (
            <table className="create-room">
                <tr>
                    <td>
                        <span className="label">Spēles veids</span>
                        <Select
                            bordered={false}
                            value={this.state.type}
                            suffixIcon={<img src={arrow} alt="\/" />}
                            onChange={(val) => this.set(`type`,  val)}
                            options={types.map(val => ({ value: val, label: <span>{val}</span> }))}
                        />
                    </td>
                    <td>
                        <Checkbox
                            style={{
                                position: `absolute`,
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                            checked={this.state.isFast}
                            onChange={(e) => this.set(`isFast`,  e.target.checked)}
                        >
                            <span className="label" style={{ color: `#B062DF` }}>Veiklā</span>
                        </Checkbox>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className="label">Likme</span>
                        <Select
                            bordered={false}
                            value={this.state.bet}
                            suffixIcon={<img src={arrow} alt="\/" />}
                            onChange={(val) => this.set(`bet`,  val)}
                            options={bets.map(val => ({ value: val, label: <Points points={val} /> }))}
                        />
                    </td>
                    <td>
                        <span className="label">Spēlētāji</span>
                        <Select
                            bordered={false}
                            value={this.state.is4Player ? 4 : 3}
                            suffixIcon={<img src={arrow} alt="\/" />}
                            onChange={(val) => this.set(`is4Player`,  val === 4)}
                            options={[3, 4].map(val => ({ value: val, label: <span>{val}</span> }))}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className="label">Min. partijas</span>
                        <Select
                            bordered={false}
                            value={this.state.minGames}
                            suffixIcon={<img src={arrow} alt="\/" />}
                            onChange={(val) => this.set(`minGames`,  val)}
                            options={games.map(val => ({ value: val, label: <span>{val}</span> }))}
                        />
                    </td>
                    <td>
                        <Checkbox
                            style={{
                                position: `absolute`,
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                            checked={this.state.isLocked}
                            onChange={(e) => this.set(`isLocked`,  e.target.checked)}
                        >
                            <span className="label" style={{ color: `#B062DF` }}>Draugu</span>
                        </Checkbox>
                    </td>
                </tr>
            </table >
        );
    }
}

export default CreateRoom;
