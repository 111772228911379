import React, { Component } from 'react';

import dailyIcon from '../../resources/leader/daily.svg';
import weeklyIcon from '../../resources/leader/weekly.svg';
import monthlyIcon from '../../resources/leader/monthly.svg';
import yearlyIcon from '../../resources/leader/yearly.svg';
import defaultIcon from '../../resources/leader/default.svg';

class Leader extends Component {
    render() {
        let icon = defaultIcon;
        let title = ``;

        switch (this.props.type) {
            case `daily`:
                icon = dailyIcon;
                title = <>Dienas līderis<br/></>;
                break;
            case `weekly`:
                icon = weeklyIcon;
                title = <>Nedēļas līderis<br/></>;
                break;
            case `monthly`:
                icon = monthlyIcon;
                title = <>Mēneša līderis<br/></>;
                break;
            case `yearly`:
                icon = yearlyIcon;
                title = <>Gada līderis<br/></>;
                break;

            default:
                icon = defaultIcon;
                break;
        }

        return (
            <div>
                <div
                    className="circle"
                    style={{
                        position: `relative`,
                        borderRadius: 100,
                        background: `white`,
                        boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.25)`,
                        margin: `auto`,
                    }}
                >
                    <img
                        src={icon}
                        alt="table"
                        style={{
                            position: `absolute`,
                            width: this.props.size > 650 ? undefined : 45,
                            top: this.props.size > 650 ? (icon === defaultIcon ? 32 : 28) : (icon === defaultIcon ? 20 : 20),
                            left: this.props.size > 650 ? (icon === defaultIcon ? 22 : 15) : (icon === defaultIcon ? 10 : 10),
                        }}
                    />
                </div>
                <p
                    style={{
                        fontFamily: `Montserrat`,
                        textAlign: `center`,
                        textTransform: `uppercase`,
                        fontWeight: `bold`,
                        fontSize: 16,
                        marginTop: 5,
                        color: `white`
                    }}
                >
                    <span>{this.props.name}<br/>{this.props.count} uzvarētas partijas</span>
                </p>
            </div>
        );
    }
}

export default Leader;
