import React, { Component } from 'react';

class DailyBonus extends Component {
    render() {
        return (
            <div>
                <div
                    className="circle"
                    style={{
                        position: `relative`,
                        borderRadius: 100,
                        background: `white`,
                        boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.25)`,
                        margin: `auto`,
                    }}
                >
                    <div className='daily-reward-progress' style={{ width: (this.props.day ?? 1) * (this.props.size > 650 ? 2 : (34 / 24)) }} />
                    <div className='daily-reward' />
                    <div className='daily-reward-count'>
                        <span>{this.props.day ?? 1}/24</span>
                    </div>
                </div>
                <p
                    style={{
                        fontFamily: `Montserrat`,
                        textAlign: `center`,
                        textTransform: `uppercase`,
                        fontWeight: `bold`,
                        fontSize: 16,
                        marginTop: 5,
                        color: `white`
                    }}
                >
                    {this.props.day ?? 1}. dienas <br/> bonuss
                </p>
            </div>
        );
    }
}

export default DailyBonus;
