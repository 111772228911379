import React from 'react';
import Layout from '../../engine/layout';
import { Typography } from 'antd';
import ReactHtmlParser from 'react-html-parser'; 

class AboutUs extends Layout {
    mobileHeadVh = 15;
    mobileHeadHeight = 150;

    constructor(props) {
        super(props);

        this.ready = false;
        this.state = {
            data: null,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        if (this.props.client) {
            const response = await this.props.client.get( `aboutUs`, 1, 100 );
            if (response.ok && response.status === 200) {
                this.setState({ data: response.body.data });
            }
        }
    }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Par mums
                </Typography.Title>
            </div>
        );
    }

    contentMobile() {
        // const { user } = this.props.client;

        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
                <div style={{ margin: `10px 40px`}}>{ReactHtmlParser(this.state.data)}</div>
            </>
        );
    }

    contentWide() {
        // const { user } = this.props.client;

        return (
            <>
                <Typography.Title level={2}>Par mums</Typography.Title>
                <div style={{ margin: `10px 40px`}}>{ReactHtmlParser(this.state.data)}</div>
            </>
        );
    }
}

export default AboutUs;
