import React, { Component } from 'react';

class GameLogs extends Component {
    render() {
        const { logs, style } = this.props;

        return (
            <div className="game-logs" style={{ ...style }}>
                {
                    [ ...logs ].slice(-2).reverse().map((log, i) => (
                        <>
                            <div className="game-log" key={`game-log-${i}`}>{log}</div>
                        </>
                    ))
                }
            </div>
        );
    }
}

export default GameLogs;
