import React, { Component } from 'react';

class MobileMenuCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hovered: false,
        }
    }
    render() {
        const Icon = this.props.icon;

        return (
            <div
                style={{
                    background: this.state.hovered ? `#c5a532` : `#E2C354`,
                    minWidth: 100,
                    maxWidth: 160,
                    width: `33vw`,
                    minHeight: 230,
                    maxHeight: 230,
                    boxShadow: this.state.hovered ? `2px 4px 4px rgba(0, 0, 0, 0.55)` : `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                    borderRadius: 6,
                    // paddingTop: 20,
                    zIndex: 9,
                    margin: `0 ${this.props.size > 575 ? `10` : `5`}px`,
                    cursor: `pointer`,
                    transition: `all .3s`,
                }}
                onMouseEnter={() => this.setState({ hovered: true })}
                onMouseLeave={() => this.setState({ hovered: false })}
                title={this.props.title}
                onClick={this.props?.onClick ?? (() => this.props?.navigate(`/admin/${this.props?.path}`))}
            >
                <div style={{ height: `100%`, display: `flex`, flexDirection: `column` }}>
                    <div
                        className="circle"
                        style={{
                            position: `relative`,
                            borderRadius: 100,
                            background: `white`,
                            boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.25)`,
                            margin: `20px auto`,
                        }}
                    >
                        <Icon style={{
                            position: `absolute`,
                            top: this.props.top ?? 20,
                            left: this.props.left ?? 20,
                            fontSize: 56,
                            color: this.state.hovered ? `#c5a532` : `#E2C354`,
                            transition: `all .3s`,
                        }} />
                    </div>
                    <p
                        style={{
                            fontFamily: `Montserrat`,
                            textAlign: `center`,
                            textTransform: `uppercase`,
                            fontWeight: `bold`,
                            fontSize: 16,
                            marginTop: 5,
                            color: `white`,
                            flexGrow: 1,
                        }}
                    >
                        {this.props.title}
                    </p>
                </div>
            </div>
        );
    }
}

export default MobileMenuCard;
