import League from "../pages/League";
import Play from "../pages/Play";
import Shop from "../pages/Shop";
import Tourney from "../pages/Tourney";
import NewTourney from "../pages/NewTourney";
import ViewTourney from "../pages/ViewTourney";
import Statistics from "../pages/Statistics";
import Achievements from "../pages/Achievements";
import Admin from "../pages/admin/Admin";
import Users from "../pages/admin/Users";
import Tourneys from "../pages/admin/Tourneys";
import Files from "../pages/admin/Files";
import Notifications from "../pages/admin/Notifications";
import Settings from "../pages/admin/Settings";
import Footer from "../pages/admin/Footer";
import AboutZole from "../pages/footer/AboutZole";
import AboutUs from "../pages/footer/AboutUs";
import Politics from "../pages/footer/Politics";
import Cooperation from "../pages/footer/Cooperation";
import Analytics from "../pages/footer/Analytics";

const routes = [
    {
        path: /^\/league\/?$/,
        title: `Zolmaņu līga`,
        component: League,
    },
    {
        path: /^\/play\/([0-9]+)$/,
        title: `Galds`,
        component: Play,
    },
    {
        path: /^\/shop\/?$/,
        title: `Papildināt ZD`,
        component: Shop,
    },
    {
        path: /^\/tourney\/new\/?$/,
        title: `Jauns turnīrs`,
        component: NewTourney,
    },
    {
        path: /^\/tourney\/([0-9]+)$/,
        title: `Turnīrs`,
        component: ViewTourney,
    },
    {
        path: /^\/tourney\/?$/,
        title: `Turnīri`,
        component: Tourney,
    },
    {
        path: /^\/statistics\/?$/,
        title: `Statistika`,
        component: Statistics,
    },
    {
        path: /^\/achievements\/?$/,
        title: `Sasniegumi`,
        component: Achievements,
    },
    {
        path: /^\/aboutZole\/?$/,
        title: `Par zolīti`,
        component: AboutZole,
    },
    {
        path: /^\/aboutUs\/?$/,
        title: `Par mums`,
        component: AboutUs,
    },
    {
        path: /^\/politics\/?$/,
        title: `Noteikumi`,
        component: Politics,
    },
    {
        path: /^\/cooperation\/?$/,
        title: `Sadarbība`,
        component: Cooperation,
    },
    {
        path: /^\/analytics\/?$/,
        title: `Analītika`,
        component: Analytics,
    },
    {
        path: /^\/admin\/?$/,
        title: `Admin`,
        component: Admin,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
    {
        path: /^\/admin\/users\/?$/,
        title: `Lietotāju saraksts`,
        component: Users,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
    {
        path: /^\/admin\/tourneys\/?$/,
        title: `Turnīru saraksts`,
        component: Tourneys,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
    {
        path: /^\/admin\/files\/?$/,
        title: `Failu saraksts`,
        component: Files,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
    {
        path: /^\/admin\/footer\/?$/,
        title: `Saturs`,
        component: Footer,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
    {
        path: /^\/admin\/settings\/?$/,
        title: `Sistēmas iestatījumi`,
        component: Settings,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
    {
        path: /^\/admin\/logs\/?$/,
        title: `Sistēmas ziņojumi`,
        component: Notifications,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
    {
        path: /^\/admin\/logs\/?$/,
        title: `Sistēmas ziņojumi`,
        component: Notifications,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
    {
        path: /^\/admin\/logs\/?$/,
        title: `Sistēmas ziņojumi`,
        component: Notifications,
        can: (user) => {
            return user.roles.includes(`Administrators`);
        }
    },
];

export default routes;