import React, { Component } from 'react';


import timer from '../../resources/timer.svg';
import lock from '../../resources/lock.svg';
import LoadingDots from './LoadingDots';
import Points from './Points';

class Gametable extends Component {
    render() {
        const { user, client } = this.props.app.state;

        return (
            <div style={{ ...(this.props.style ?? {}) }}>
                <table className="games" style={{ ...(this.props.tableStyle ?? {}) }}>
                    {
                        this.props.data.map((table) =>
                            <>
                                <tr>
                                    <td style={{
                                        width: `20vw`,
                                        minWidth: 80,
                                        maxWidth: 180,
                                    }}>
                                        {table.minGames > 1 && `${table.minGames} `}
                                        {table.type}
                                        {!!table.is4Player && `4`}
                                        {!!table.isFast && <img src={timer} alt="timer" />}
                                        {!!table.isLocked && <img src={lock} alt="lock" />}
                                    </td>
                                    <td style={{
                                        width: `15vw`,
                                        minWidth: 60,
                                        maxWidth: 120,
                                    }}>
                                        <Points points={table.bet} />
                                    </td>
                                    <td>
                                        <div style={{ width: `100%` }}>
                                            {
                                                table.players && 0 in table.players ? (
                                                    <span style={{
                                                        width: `33%`,
                                                        display: `inline-block`,
                                                        textAlign: `center`,
                                                        verticalAlign: `sub`,
                                                    }}>
                                                        {table.players[0].name}
                                                    </span>
                                                ) : (
                                                    <div style={{
                                                        width: `33%`,
                                                        display: `inline-block`,
                                                        paddingBottom: 3,
                                                    }}>
                                                        <LoadingDots count={3} style={{ margin: `auto` }} />
                                                    </div>
                                                )
                                            }
                                            {
                                                table.players && 1 in table.players ? (
                                                    <span style={{
                                                        width: `33%`,
                                                        textAlign: `center`,
                                                        verticalAlign: `sub`,
                                                        display: `inline-block`,
                                                    }}>
                                                        {table.players[1].name}
                                                    </span>
                                                ) : (
                                                    <div style={{
                                                        width: `33%`,
                                                        display: `inline-block`,
                                                        paddingBottom: 3,
                                                    }}>
                                                        <LoadingDots count={3} style={{ margin: `auto` }} />
                                                    </div>
                                                )
                                            }
                                            {
                                                table.players && 2 in table.players ? (
                                                    <span style={{
                                                        width: `33%`,
                                                        textAlign: `center`,
                                                        verticalAlign: `sub`,
                                                        display: `inline-block`
                                                    }}>
                                                        {table.players[2].name}
                                                    </span>
                                                ) : (
                                                    <div style={{
                                                        width: `33%`,
                                                        display: `inline-block`,
                                                        paddingBottom: 3,
                                                    }}>
                                                        <LoadingDots count={3} style={{ margin: `auto` }} />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </td>
                                    {
                                        client.window.size.width > 650 && (
                                            <td style={{ width: 90, border: `none` }}>
                                                <div
                                                    onClick={() => this.props.onJoin(table.id)}
                                                    className={`button${user.lobby && user.lobby !== table.id ? ` inactive` : ``}`}
                                                >
                                                    {user.lobby && user.lobby === table.id ? `PAMEST` : `SPĒLĒT`}
                                                </div>
                                            </td>
                                        )
                                    }
                                </tr>
                                {
                                    client.window.size.width <= 650 && (
                                        <tr>
                                            <td colSpan={3}>
                                                <div
                                                    className={`button${user.lobby && user.lobby !== table.id ? ` inactive` : ``}`}
                                                    style={{
                                                        width: 92,
                                                        textAlign: `center`,
                                                        float: `right`,
                                                        padding: `5px 0`,
                                                        margin: `10px 0`,
                                                    }}
                                                    onClick={() => this.props.onJoin(table.id)}
                                                >
                                                    {user.lobby && user.lobby === table.id ? `PAMEST` : `SPĒLĒT`}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        this.props.size > this.props.data.length && (
                            <tr onClick={this.props.onMore} className="last">
                                <td colSpan={3}>...</td>
                            </tr>
                        )
                    }
                </table>
            </div>
        );
    }
}

export default Gametable;
