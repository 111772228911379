import React, { useContext } from 'react';
import Layout from '../../engine/layout';
import { Form, Popconfirm, Select, Typography, Upload, notification } from 'antd';
import DataTable from '../../engine/widgets/DataTable';
import moment from 'moment';
import { InboxOutlined } from '@ant-design/icons';
import { AppContext } from '../../App';

const FilesWrapper = (props) => {
    const app = useContext(AppContext);

    return <Files app={app} {...props} />;
}
class Files extends Layout {
    constructor(props) {
        super(props);
        this.app = props.app;

        this.ready = false;
        this.mobileHeadVh = 15;
        this.mobileHeadHeight = 150;

        this.state = {
            types: {},
            ctx: 1,
        }
    }

    componentDidMount() {
        this.fetchData();
        this.app = this.props.app;
    }

    componentDidUpdate() {
        this.app = this.props.app;
    }

    getFileUrl = (file) => {
        let ft = null;
        for (const type of Object.keys(this.state.types)) {
            if (file.type === this.state.types[type]) {
                ft = type;
            }
        }

        return ft ? `/files/${ft}` : null;
    };

    fetchData = async () => {
        const response = await this.app.fn.api.get( `admin/fileTypes`, 1, 100 );
        if (response.ok && response.status === 200) {
            this.setState({ types: { ...response.body.types } });
        }
    }
    
    deleteFile = async (id) => {
        const response = await this.app.fn.api.delete( `admin/files`, id );
        if (response.ok && response.status === 200) {
            notification.success({
                duration: 30,
                message: response.body.title ?? `Fails dzēsts`,
                description: response.body.error ?? `Fails veiksmīgi dzēsts.`,
            });
            this.setState((old) => ({ ctx: old.ctx + 1 }));
        } else {
            notification.error({
                duration: 60,
                message: response.body.title ?? `Mēģiniet vēlreiz!`,
                description: response.body.error ?? `Kaut kas nogāja greizi - neizdevās dzēst failu.`,
            });
        }
    }


    submitData = async (data) => {
        const formData = new FormData();
        formData.append(`file`, data.file?.file);
        formData.append(`type`, data.type);

        this.setState({ submiting: true });
        const response = await this.app.fn.api.post(
            `admin/files`,
            formData,
            null,
            `v1`,
            false
        );
        if (response.ok && response.status === 200) {
            notification.success({
                duration: 30,
                message: response.body.title ?? `Fails augšupielādēts`,
                description: response.body.error ?? `Fails veiksmīgi augšupielādēts.`,
            });
            this.setState((old) => ({ ctx: old.ctx + 1 }));
        } else {
            notification.error({
                duration: 60,
                message: response.body.title ?? `Mēģiniet vēlreiz!`,
                description: response.body.error ?? `Kaut kas nogāja greizi - neizdevās augšupielādēt failu.`,
            });
        }
        this.setState({ submiting: false });
    }

    content() {
        const { width } = this.app.state.client.window.size;
        if (width > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Failu saraksts
                </Typography.Title>
            </div>
        );
    }

    contentMobile() {
        return (
            <>
                <div style={{ height: 50, width: `100%` }} />'
                <DataTable
                    app={this.app}
                    key={`files-${this.state.ctx}`}
                    columns={[
                        {
                            title: `Nosaukums`,
                            key: `name`,
                            render: (val, row) => {
                                const url = this.getFileUrl(row);
                                if (url) {
                                    return <a href={url} target='blank'>{val}</a>
                                } else {
                                    return <span>{val}</span>;
                                }
                            }
                        },
                        {
                            title: `Tips`,
                            key: `type`,
                        },
                        {
                            title: `Datums`,
                            key: `created_at`,
                            render: (val) => {
                                return moment(val).format(`DD.MM.YYYY HH:mm`);
                            },
                        },
                        {
                            title: `Darbība`,
                            key: `x`,
                            render: (x, row) => {
                                return (
                                    <Popconfirm
                                        title="Vai tiešām vēlies dzēst šo failu?"
                                        onConfirm={() => this.deleteFile(row.id)}
                                        okText="Dzēst"
                                        cancelText={<span style={{ color: `black` }}>Atcelt</span>}
                                        okButtonProps={{ danger: true }}
                                    >
                                        <Typography.Link>Dzēst</Typography.Link>
                                    </Popconfirm>
                                );
                            },
                        },
                    ]}
                    source="admin/files"
                    // onAdd={() => {
                    //     this.setState((old) => ({ ctx: old.ctx + 1 }));
                    // }}
                    submitData={(data) => this.submitData(data)}
                    addForm={(
                        <>
                            <Form.Item rules={[{ required: true, message: 'Norādiet dokumenta tipu' }]} name="type">
                                <Select
                                    style={{ width: `80%` }}
                                    placeholder="Dokumenta tips"
                                    options={Object.keys(this.state.types).map((val) => (
                                        { value: this.state.types[val], label: this.state.types[val] }
                                    ))}
                                />
                            </Form.Item>
                            <Form.Item  rules={[{ required: true, message: 'Pievienojiet failu' }]} name="file">
                                <Upload.Dragger 
                                    name="file"
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                    onDrop={(e) => {
                                        // console.log('Dropped files', e.dataTransfer.files);
                                    }}
                                    maxCount={1}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Nospied šeit vai uzvelc failu</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </>
                    )}
                />
            </>
        );
    }

    contentWide() {
        return (
            <>
                <Typography.Title level={2}>Failu saraksts</Typography.Title>
                <DataTable
                    app={this.app}
                    key={`files-${this.state.ctx}`}
                    columns={[
                        {
                            title: `Nosaukums`,
                            key: `name`,
                            render: (val, row) => {
                                const url = this.getFileUrl(row);
                                if (url) {
                                    return <a href={url} target='blank'>{val}</a>
                                } else {
                                    return <span>{val}</span>;
                                }
                            }
                        },
                        {
                            title: `Tips`,
                            key: `type`,
                        },
                        {
                            title: `Datums`,
                            key: `created_at`,
                            render: (val) => {
                                return moment(val).format(`DD.MM.YYYY HH:mm`);
                            },
                        },
                        {
                            title: `Darbība`,
                            key: `x`,
                            render: (x, row) => {
                                return (
                                    <Popconfirm
                                        title="Vai tiešām vēlies dzēst šo failu?"
                                        onConfirm={() => this.deleteFile(row.id)}
                                        okText="Dzēst"
                                        cancelText={<span style={{ color: `black` }}>Atcelt</span>}
                                        okButtonProps={{ danger: true }}
                                    >
                                        <Typography.Link>Dzēst</Typography.Link>
                                    </Popconfirm>
                                );
                            },
                        },
                    ]}
                    source="admin/files"
                    submitData={(data) => this.submitData(data)}
                    // onAdd={() => {
                    //     this.setState((old) => ({ ctx: old.ctx + 1 }));
                    // }}
                    addForm={(
                        <>
                            <Form.Item rules={[{ required: true, message: 'Norādiet dokumenta tipu' }]} name="type">
                                <Select
                                    style={{ width: `80%` }}
                                    placeholder="Dokumenta tips"
                                    options={Object.keys(this.state.types).map((val) => (
                                        { value: this.state.types[val], label: this.state.types[val] }
                                    ))}
                                />
                            </Form.Item>
                            <Form.Item  rules={[{ required: true, message: 'Pievienojiet failu' }]} name="file">
                                <Upload.Dragger 
                                    name="file"
                                    beforeUpload={(file) => {
                                        return false;
                                    }}
                                    onDrop={(e) => {
                                        // console.log('Dropped files', e.dataTransfer.files);
                                    }}
                                    maxCount={1}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Nospied šeit vai uzvelc failu</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </>
                    )}
                />
            </>
        );
    }
}

export default FilesWrapper;
