import React, { useContext } from 'react';
import Layout from '../engine/layout';
import { Typography } from 'antd';
import DailyBonus from '../engine/widgets/DailyBonus';
import TablesPlayed from '../engine/widgets/Tables';
import Leader from '../engine/widgets/Leader';
import Gametable from '../engine/widgets/GameTable';
import CreateRoom from '../engine/widgets/CreateRoom';
import { AppContext } from '../App';

const LeagueWrapper = (props) => {
    const app = useContext(AppContext);

    return <League app={app} {...props} />;
}

class League extends Layout {
    constructor(props) {
        super(props);
        this.app = props.app;

        this.ready = false;
        this.league = false;
        this.timeout = false;

        this.roomData = {
            type: `P`,
            bet: 1000,
            minGames: 1,
            isLocked: false,
            isFast: true,
            is4Player: false,
        };

        this.state = {
            pageSize: 3,
            activeRooms: 0,
            openRooms: 0,
            rooms: [],
            page: 1,
        };
    }

    connect = () => {
        const { websock } = this.app.state;

        if (websock.ready && !this.league) {
            this.league = this.app.fn.websock.subscribe(`league`);
            if (!this.league) return;

            this.league.on(`lobby.created`, (e) => {
                console.log(`lobby.created`, {...e});
                const { rooms, page, pageSize: size } = this.state;

                const isNew = rooms.filter(r => r.id === e.id).length === 0;
                if (!isNew) {                        
                    this.setState({ rooms: rooms.map(r => r.id === e.id ? { ...e } : { ...r }) });
                } else if (rooms.length < size * page) {
                    if (this.includesCurrentUser([ { ...e } ])) {
                        this.app.fn.websock.subscribe(`lobby.${e.id}`)
                            .on(`lobby.ready`, (e) => {
                                // this.props.client.game = { id: e.game.id };
                                // this.props.client.lobby = { id: e.lobby.id };
                                this.league = false;
                                this.app.fn.websock.unsubscribe(`league`);
                                this.app.fn.client.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
                            });
                            
                        this.setState({ rooms: [ { ...e }, ...rooms ] });
                    } else {
                        this.setState({ rooms: [ ...rooms, { ...e } ] });
                    }
                }
            });
            this.league.on(`lobby.closed`, (e) => {
                console.log(`lobby.closed`, {...e});
                const { rooms } = this.state;

                // if (e.id === user.lobby) {
                //     user.setLobby(null);
                // }

                this.setState({ rooms: rooms.filter(r => r.id !== e.id ) });
                // this.refresh();
            });
            this.league.on(`lobby.joined`, (e) => {
                const { user } = this.app.state;
                const { rooms } = this.state;

                console.log(`lobby.joined`, {...e});

                if (e.user.id === user.id) {
                    this.app.fn.user.updateLobby(e.lobby.id);
                }

                const lobbies = [];
                for (const room of rooms) {
                    if (room.id === e.lobby.id) {
                        if (room.players.filter(p => p.id === e.user.id).length > 0) {
                            room.players = room.players.map(p => p.id === e.user.id ? { ...e.user } : { ...p });
                        } else {
                            room.players.push({ ...e.user });
                        }
                    }

                    if (room.players.length < rooms.is4Player ? 4 : 3) {
                        lobbies.push({ ...room });
                    }
                }

                this.setState({ rooms: [ ...lobbies ] });
                // this.refresh();
            });
            this.league.on(`lobby.left`, (e) => {
                // console.log(`lobby.left`, {...e});
                const { rooms } = this.state;
                const { user } = this.app.state;

                if (e.lobby.id === user.lobby && e.user.id === user.id) {
                    this.app.fn.user.updateLobby(null);
                }

                const lobbies = [];
                for (const room of rooms) {
                    if (room.id === e.lobby.id) {
                        room.players = room.players.filter((p) => p.id !== e.user.id);
                    }
                    if ([...room.players].length > 0) {
                        lobbies.push({ ...room });
                    }
                }

                this.setState({ rooms: [ ...lobbies ] });
                // this.refresh();
            });
            this.league.on(`lobby.list`, (e) => {
                // console.log(`lobby.list`, {...e});
                // this.includesCurrentUser([ ...e.rooms ])
                this.setState({ rooms: [ ...e.rooms ] });
            });
            this.league.on(`lobby.started`, (e) => {
                console.log(`lobby.started`, {...e});
                const { rooms } = this.state;

                this.setState({ rooms: rooms.filter(r => r.id !== e.lobby.id ) });
                // this.refresh();
            });
            
            this.reload();
        } else if (!websock.ready) {
            this.league = false;

            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = false;
            }
        }
    };

    componentDidMount() {
        this.app = this.props.app;
        window._ws_reload = (force = true) => this.reload(force);

        this.connect();
    }

    componentDidUpdate() {
        this.app = this.props.app;

        this.connect();
    }

    includesCurrentUser = (lobbies) => {
        const { user } = this.app.state;

        if (user.id) {
            for (const lobby of lobbies) {
                for (const player of lobby.players) {
                    if (player.id === user.id) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    content() {
        const { width } = this.app.state.client.window.size;
        if (width > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    reload = (force = false) => {
        if (force) {
            const { websock } = this.app.state;
            const { page, pageSize: size } = this.state;
    
            if (websock.ready && this.league) {
                // console.log(`Reloading lobbies (force = ${force ? `TRUE` : `FALSE`}).`);
                this.league.whisper(`lobby.reload`, { pages: page, size: size });
            }

            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = false;
            }
        }
        
        if (!this.timeout) {
            this.timeout = setTimeout(() => {
                // console.log(`Reloading league via timeout.`);
                this.timeout = false;
                this.reload(true);
            }, 1000); // 400
        }
    };

    create = async () => {
        const { user } = this.app.state;
        const { websock } = this.app.fn;
        const { ready } = this.app.state.websock;

        if (user.lobby !== null) return;

        if (ready) {
            websock.subscribe(`league`).whisper(`lobby.create`, { ...this.roomData });
        }
    };

    joinOrLeave = async (id) => {
        const { user } = this.app.state;
        const { websock, user: usr } = this.app.fn;
        const { ready } = this.app.state.websock;

        if (ready) {
            if (user.lobby === id) {
                user.lobby = null;
                websock.unsubscribe(`lobby.${id}`);
            } else {
                user.lobby = id;
                websock.subscribe(`lobby.${id}`)
                    .on(`lobby.invite`, (e) => {
                        usr.updateLobby(e.lobby.id);
                        websock.unsubscribe(`league`);
                        this.app.fn.client.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
                    }).on(`game.invite`, (e) => {
                        // this.props.client.game = { id: e.game.id };
                        websock.unsubscribe(`league`);
                        this.app.fn.client.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
                    }).on(`lobby.ready`, (e) => {
                        // this.props.client.game = { id: e.game.id };
                        usr.updateLobby(e.lobby.id);
                        websock.unsubscribe(`league`);
                        this.app.fn.client.navigate( `/play/${e.lobby.id}`, 'Zolmaņiem | Spēlēt' );
                    });
            }
        }
    };

    head() {
        const { user, client } = this.app.state;

        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Zolmaņu līga
                </Typography.Title>
                <div style={{
                    padding: `0 10px`,
                    display: `flex`,
                    width: `100%`,
                    justifyContent: `center`
                }}>
                    <div
                        style={{
                            background: `#E2C354`,
                            minWidth: 100,
                            maxWidth: 160,
                            width: `33vw`,
                            minHeight: 230,
                            maxHeight: 230,
                            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                            borderRadius: 6,
                            paddingTop: 20,
                            zIndex: 9,
                            margin: `0 ${this.props.size > 575 ? `10` : `5`}px`,
                        }}
                    >
                        <DailyBonus day={user.dailyBonus} size={client.window.size.width} />
                    </div>
                    <div
                        style={{
                            background: `#E2C354`,
                            minWidth: 100,
                            maxWidth: 160,
                            width: `33vw`,
                            minHeight: 230,
                            maxHeight: 230,
                            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                            borderRadius: 6,
                            paddingTop: 20,
                            zIndex: 9,
                            margin: `0 ${client.window.size.width > 575 ? `10` : `5`}px`,
                        }}
                    >
                        <TablesPlayed count={0} size={client.window.size.width} />
                    </div>
                    <div
                        style={{
                            background: `#E2C354`,
                            minWidth: 100,
                            maxWidth: 160,
                            width: `33vw`,
                            minHeight: 230,
                            maxHeight: 230,
                            boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                            borderRadius: 6,
                            paddingTop: 20,
                            zIndex: 9,
                            margin: `0 ${client.window.size.width > 575 ? `10` : `5`}px`,
                        }}
                    >
                        <Leader name="TEST ONE" count={0} size={client.window.size.width} />
                    </div>
                </div>
            </div>
        );
    }

    contentMobile() {
        // const { user } = this.app.state;

        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
                <div style={{
                    display: `table`,
                    margin: `auto`,
                    maxWidth: 800,
                }}>
                    <Gametable
                        app={this.app}
                        style={{ display: `block`, maxWidth: 800 }}
                        tableStyle={{ maxWidth: 800, width: `100%` }}
                        data={this.state.rooms}
                        size={this.state.openRooms}
                        onMore={() => this.setState(old => ({ page: old.page + 1 }))}
                        onJoin={(id) => this.joinOrLeave(id)}
                    />
                    <CreateRoom callback={(data) => this.roomData = { ...data }} />
                    <div
                        // className={`button${user.lobby ? ` inactive` : ``}`}
                        className="button"
                        style={{
                            display: `inline-block`,
                            marginTop: 35,
                            marginLeft: 10,
                        }}
                        onClick={() => this.create()}
                    >
                        Izveidot galdu
                    </div>
                    <Typography.Text style={{
                        color: `#606060`,
                        fontSize: 16,
                        display: `block`,
                        marginTop: 50,
                        marginLeft: 10,
                        maxWidth: 800,
                        width: `100%`,
                    }}>
                        P - ar pulēm, G - ar galdiņu, PM - ar pulēm un mazo zoli, GM - ar galdiņu un mazo zoli, 4 - zole četratā, cipars - minimālais partiju skaits,      - veiklā,
                        - draugu galds
                    </Typography.Text>
                </div>
            </>
        );
    }

    contentWide() {
        const { user, client } = this.app.state;

        return (
            <>
                <table
                    style={{
                        width: `100%`,
                    }}
                >
                    <tr>
                        <td
                            rowSpan={2}
                            style={{
                                verticalAlign: `top`,
                                width: 200,
                            }}
                        >
                            <div
                                style={{
                                    background: `#E2C354`,
                                    width: 160,
                                    minHeight: 400,
                                    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
                                    borderRadius: 6,
                                    paddingTop: 35,
                                }}
                            >
                                <DailyBonus day={user.dailyBonus} size={client.window.size.width} />
                                <TablesPlayed count={0} size={client.window.size.width} />
                                <Leader name="Jānis Pirmais" count={1234} size={client.window.size.width} />
                                <br />
                            </div>
                        </td>
                        <td
                            style={{
                                verticalAlign: `top`,
                            }}
                        >
                            <Typography.Title level={2}>Zolmaņu līga</Typography.Title>
                            <Gametable
                                app={this.app}
                                data={this.state.rooms}
                                size={this.state.openRooms}
                                onMore={() => this.setState(old => ({ page: old.page + 1 }))}
                                onJoin={(id) => this.joinOrLeave(id)}
                            />
                            <CreateRoom callback={(data) => this.roomData = { ...data }} />
                            <div
                                className={`button${user.lobby ? ` inactive` : ``}`}
                                style={{
                                    display: `inline-block`,
                                    marginTop: 35,
                                    marginLeft: 10,
                                }}
                                onClick={() => this.create()}
                            >
                                Izveidot galdu
                            </div>
                            <Typography.Text style={{
                                color: `#606060`,
                                fontSize: 16,
                                display: `block`,
                                marginTop: 50,
                                marginLeft: 10,
                            }}>
                                P - ar pulēm, G - ar galdiņu, PM - ar pulēm un mazo zoli, GM - ar galdiņu un mazo zoli, 4 - zole četratā, cipars - minimālais partiju skaits,      - veiklā,
                                - draugu galds
                            </Typography.Text>
                        </td>
                    </tr>
                </table>
            </>
        );
    }
}

export default LeagueWrapper;
