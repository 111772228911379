import React, { Component } from 'react';

import table from '../../resources/tablesPlayed.svg'

class Tables extends Component {
    render() {
        return (
            <div>
                <div
                    className="circle"
                    style={{
                        position: `relative`,
                        borderRadius: 100,
                        background: `white`,
                        boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.25)`,
                        margin: `auto`,
                    }}
                >
                    <img
                        src={table}
                        alt="table"
                        style={{
                            position: `absolute`,
                            top: (this.props.size > 650 ? 15 : 8),
                            left: (this.props.size > 650 ? 22 : 13),
                            width: (this.props.size > 650 ? undefined : 40),
                        }}
                    />
                    <div className='daily-reward-count'>
                        <span>{this.props.count ?? 0}</span>
                    </div>
                </div>
                <p
                    style={{
                        fontFamily: `Montserrat`,
                        textAlign: `center`,
                        textTransform: `uppercase`,
                        fontWeight: `bold`,
                        fontSize: 16,
                        marginTop: 5,
                        color: `white`
                    }}
                >
                    Galdi
                </p>
            </div>
        );
    }
}

export default Tables;
