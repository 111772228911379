import React from 'react';
import Layout from '../../engine/layout';
import { Typography } from 'antd';

class Analytics extends Layout {
    mobileHeadVh = 15;
    mobileHeadHeight = 150;

    constructor(props) {
        super(props);

        this.ready = false;
    }

    // componentDidMount() {
    // }

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Statistika
                </Typography.Title>
            </div>
        );
    }

    contentMobile() {
        // const { user } = this.props.client;

        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
            </>
        );
    }

    contentWide() {
        // const { user } = this.props.client;

        return (
            <>
                <Typography.Title level={2}>Statistika</Typography.Title>
            </>
        );
    }
}

export default Analytics;
