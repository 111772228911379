import React, { useContext } from 'react';
import Layout from '.';
import { Result } from 'antd';
import { AppContext } from '../../App';

const PageNotFoundWrapper = (props) => {
    const app = useContext(AppContext);

    return <PageNotFound app={app} {...props} />;
}

class PageNotFound extends Layout {
    constructor(props) {
        super(props);
        this.app = props.app;
        this.mobileHeadVh = 15;
        this.mobileHeadHeight = 300;
    }

    componentDidMount() {
        this.app = this.props.app;
        window._ws_reload = (force = true) => this.reload(force);
    }

    componentDidUpdate() {
        this.app = this.props.app;
    }

    content() {
        const { width } = this.app.state.client.window.size;
        if (width > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return null;
    }

    contentMobile() {
        return (
            <div className="error" style={{ marginTop: 0 }}>
                <Result
                    status="error"
                    title={this.props.title ?? `404 Lapa nav atrasta`}
                    subTitle={this.props.subTitle ?? `Šāda lapa nav atrasta`}
                    extra={null}
                />
            </div>
        );
    }

    contentWide() {
        return (
            <div className="error" style={{ marginTop: 60 }}>
                <Result
                    status="error"
                    title={this.props.title ?? `404 Lapa nav atrasta`}
                    subTitle={this.props.subTitle ?? `Šāda lapa nav atrasta`}
                    extra={null}
                />
            </div>
        );
    }
}

export default PageNotFoundWrapper;
