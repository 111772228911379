import React, { useContext } from 'react';
import Layout from '../../engine/layout';
import { Typography } from 'antd';
import { AppContext } from '../../App';

const SettingsWrapper = (props) => {
    const app = useContext(AppContext);

    return <Settings app={app} {...props} />;
}

class Settings extends Layout {
    constructor(props) {
        super(props);
        this.app = props.app;

        this.ready = false;
        this.mobileHeadVh = 15;
        this.mobileHeadHeight = 150;
    }

    componentDidMount() {
        this.app = this.props.app;
    }

    componentDidUpdate() {
        this.app = this.props.app;
    }

    content() {
        const { width } = this.app.state.client.window.size;
        if (width > 1380) {
            return this.contentWide();
        }

        return this.contentMobile();
    }

    head() {
        return (
            <div style={{
                position: `absolute`,
                height: `max(25vh, 300px)`,
                top: 0,
                left: 0,
                right: 0,
            }}>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#F3DD8E`,
                        fontSize: 36,
                        margin: `4vh 0 30px 0`,
                        textAlign: `center`
                    }}
                >
                    Sistēmas iestatījumi
                </Typography.Title>
            </div>
        );
    }

    contentMobile() {
        return (
            <>
                <div style={{ height: 50, width: `100%` }} />
                ..
            </>
        );
    }

    contentWide() {
        return (
            <>
                <Typography.Title level={2}>Sistēmas iestatījumi</Typography.Title>
                ..
            </>
        );
    }
}

export default SettingsWrapper;
