import { PlusOutlined } from '@ant-design/icons';
import { Empty, Form, Modal, Spin, Table, notification } from 'antd';
import React, { Component } from 'react';

class DataTable extends Component {
    constructor(props) {
        super(props);
        this.app = props.app;

        this.state = {
            data: [],
            modal: false,
            loading: false,
            submiting: false,
            id: null,
            record: null,
        }
    }

    componentDidMount() {
        this.fetchData();
        this.app = this.props.app;
    }
    
    componentDidUpdate() {
        this.app = this.props.app;
    }

    submitData = async (data) => {
        if (this.props.source) {
            this.setState({ submiting: true });
            const response = await this.app.fn.api.post(
                `${this.props.source}${this.state.id ? `/${this.state.id}` : ``}`,
                { ...data },
                null,
                `v1`
            );
            if (response.ok && response.status === 200) {
                notification.success({
                    duration: 30,
                    message: response.body.title ?? `Darbība veiksmīga`,
                    description: response.body.error ?? `Tava darbība ir saglabāta.`,
                });
                if (this.props.onAdd) {
                    this.props.onAdd();
                }
            } else {
                notification.error({
                    duration: 60,
                    message: response.body.title ?? `Mēģiniet vēlreiz!`,
                    description: response.body.error ?? `Kaut kas nogāja greizi - mēģiniet vēlreiz.`,
                });
            }
            this.setState({ submiting: false });
        }
    } 

    fetchData = async () => {
        if (this.props.source && !this.props.data) {
            this.setState({ loading: true, submiting: false });
            const response = await this.app.fn.api.get( this.props.source, 1, 100 );
            if (response.ok && response.status === 200) {
                this.setState({ data: [ ...response.body.data ] });
            }
            this.setState({ loading: false, submiting: false });
        }
    }

    render() {
        const columns = this.props.columns.map((col) => ({ ...col, dataIndex: col.key }));
        return (
            <>
                <Modal
                    centered
                    title={this.state.id === null ? `Pievienot ierakstu` : `Labot ierakstu`}
                    visible={this.state.modal}
                    // onOk={() => this.setState({ modal: false })}
                    onCancel={() => this.setState({ modal: false })}
                    // okButtonProps={{ disabled: true }}
                    // cancelButtonProps={{ disabled: true }}
                    footer={null}
                    style={{
                        ...(`modalStyle` in this.props ? this.props.modalStyle : {})
                    }}
                    destroyOnClose
                >
                    <Form
                        initialValues={
                            this.state.id !== null ?
                                this.state.record :
                                (
                                    `initalValues` in this.props ?
                                    this.props.initalValues :
                                    {}
                                )
                        }
                        onFinish={(values) => `submitData` in this.props ? this.props.submitData(values) : this.submitData(values)}
                        autoComplete="off"
                    >
                        {
                            this.state.id === null && this.props.addForm
                        }
                        {
                            this.state.id !== null && this.props.editForm
                        }
                        <button
                            className={`button${this.state.submiting ? ` inactive` : ``}`}
                            style={{
                                display: `inline-block`,
                                padding: `5px 15px`,
                                fontSize: 16,
                            }}
                            disabled={this.state.submiting}
                            type="submit"
                            // onClick={() => { this.setState({ modal: false }) }}
                        >
                            {this.state.submiting ? <Spin size="small" /> : <PlusOutlined />}
                            <span style={{ marginLeft: 5 }}>{this.state.id === null ? `Pievienot` : `Labot`}</span>
                        </button>
                    </Form>
                </Modal>
                {
                    !!this.props.addForm && (
                        <div
                            className="button"
                            style={{
                                display: `inline-block`,
                                marginBottom: 25,
                                marginLeft: 10,
                                padding: `10px 25px`,
                                fontSize: 16,
                            }}
                            onClick={() => { this.setState({ modal: true, record: null, id: null }) }}
                        >
                            <PlusOutlined /> Pievienot
                        </div>
                    )
                }
                <Table
                    columns={[...columns]}
                    dataSource={this.state?.data ?? []}
                    loading={this.state.loading}
                    locale={{
                        emptyText: <Empty style={{ margin: `50px auto` }} description="Nav datu" />
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: event => {
                            if (!!this.props.editForm) {
                                console.log(record, rowIndex, event);
                                this.setState({ id: record.id, record: record, modal: true });                                
                            }
                        },
                      };
                    }}
                />
            </>
        )
    }
}

export default DataTable;
