import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Table, Typography } from 'antd';
import React, { useContext } from 'react';
import Layout from '../engine/layout';
import moment from 'moment';
import Points from '../engine/widgets/Points';
// import { required } from '../engine/client';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import logo from '../resources/logo.png';
import timer from '../resources/timer.svg';
import calendar from '../resources/calendar.svg';
import clock from '../resources/clock.svg';
import { AppContext } from '../App';

const { Link } = Typography;

const ViewTourneyWrapper = (props) => {
    const app = useContext(AppContext);

    return <ViewTourney app={app} {...props} />;
}

class ViewTourney extends Layout {
    constructor(props) {
        super(props);
        this.app = props.app;

        this.state = {
            name: `Turnīrs`,
            participants: [],
        };
    }

    componentDidMount() {
        this.app = this.props.app;
        this.fetchData();
    }

    componentDidUpdate() {
        this.app = this.props.app;
    }

    fetchData = async () => {
        console.log(this.app.state.client.route.results);
        const gameId = [ ...this.app.state.client.route.results ?? [`null`] ].pop();
        const response = await this.app.fn.api.get( `tourneys/${gameId}` );
        if (response.ok && response.status === 200) {
            this.setState({ ...response.body });
        }
    };

    content() {
        const { size } = this.props;
        if (size > 1380) {
            return this.contentWide();
        }

        return this.contentWide();
    }

    head() {
        return null;
    }

    contentMobile() {
        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    Jauns turnīrs
                </Typography.Title>
            </>
        );
    }

    contentWide() {
        const { state } = this;

        return (
            <>
                <Typography.Title
                    level={2}
                    style={{
                        color: `#50027E`,
                        fontSize: 40,
                        fontFamily: `Montserrat`,
                        margin: `35px 0 0 0`,
                        textTransform: `uppercase`,
                        fontWeight: 600,
                    }}
                >
                    {state.name}
                </Typography.Title>
                <Table
                    columns={[
                        {
                            title: `Vieta`,
                            dataIndex: `place`,
                            key: `place`,
                        },
                        {
                            title: `Vārds`,
                            dataIndex: `name`,
                            key: `name`,
                        },
                        {
                            title: `Punkti`,
                            dataIndex: `points`,
                            key: `points`,
                        },
                        {
                            title: `Status`,
                            dataIndex: `status`,
                            key: `status`,
                        },
                    ]}
                    dataSource={state.participants?.map((v, i) => ({
                        ...v,
                        key: v.id,
                        place: `${i + 1}.`,
                        name: `${v.user.name} ${v.user.surname}`,
                    })) ?? []}
                />
            </>
        );
    }
}

export default ViewTourneyWrapper;
