// import { FilePdfOutlined } from "@ant-design/icons";

const footer = [
    {
        key: `aboutZole`,
        type: `link`,
        href: `/aboutZole`,
        title: `Par zolīti`,
        sub: [
            {
                key: `about`,
                title: `Par zolīti`,
            },
            {
                key: `notation`,
                title: `Spēļu apzīmējumi`,
            },
            {
                key: `start`,
                title: `Spēles uzsākšana`,
            },
            {
                key: `gameplay`,
                title: `Spēlēšana`,
            },
            {
                key: `league`,
                title: `Zolmaņu līga`,
            },
            {
                key: `tourneys`,
                title: `Turnīri`,
            },
            {
                key: `rules`,
                type: `link`,
                href: `/files/ZOLITES_NOTEIKUMI`,
                title: `Zolītes noteikumi`,
            },
            {
                key: `rules`,
                type: `link`,
                href: `/files/ZOLITES_TURNIRU_NOTEIKUMI`,
                title: `Tiešsaistes zolītes turnīru nolikums`,
            },
        ],
    },
    {
        key: `rules`,
        type: `link`,
        href: `/politics`,
        title: `Noteikumi`,
        sub: [
            {
                key: `website-rules`,
                type: `link`,
                href: `/files/LIETOSANAS_NOTEIKUMI`,
                title: `Mājas lapas lietošanas noteikumi`,
            },
            {
                key: `privacy`,
                type: `link`,
                href: `/files/PRIVATUMA_NOTEIKUMI`,
                title: `Privātuma noteikumi`,
            },
            {
                key: `cookies`,
                type: `link`,
                href: `/files/SIKDATNU_NOTEIKUMI`,
                title: `Sīkdatņu noteikumi`,
            },
            {
                key: `data-process`,
                type: `link`,
                href: `/files/DATU_APSTRADES_NOTEIKUMI`,
                title: `Datu apstrādes noteikumi`,
            },
            {
                key: `distance`,
                type: `link`,
                href: `/files/DISTANCES_LIGUMS`,
                title: `Distances līgums`,
                sub: [
                    {
                        key: `website-rules`,
                        type: `link`,
                        href: `/files/ATTEIKUMA_VEIDLAPA`,
                        title: `Atteikuma veidlapa`,
                    },
                ],
            },
            {
                key: `subscription`,
                type: `link`,
                href: `/files/REKLAMAS_NOTEIKUMI`,
                title: `Reklamas noteikumi`,
            },
        ],
    },
    {
        key: `about`,
        type: `link`,
        href: `/aboutUs`,
        title: `Par mums`,
        sub: [
            {
                key: `contacts`,
                title: `Kontakti`,
            },
            {
                key: `philosophy`,
                title: `Filozofija`,
            },
            {
                key: `vision`,
                title: `Vīzija`,
            },
            {
                key: `mission`,
                title: `Misija`,
            },
            {
                key: `values`,
                title: `Vērtības`,
            },
        ],
    },
    {
        key: `cooperation`,
        type: `link`,
        href: `/cooperation`,
        // onClick: () => {
        //     window.open(`${document.location.origin}/cooperation`);
        // },
        // style: {
        //     cursor: `alias`,
        // },
        // icon: <FilePdfOutlined  className="pdf-icon" />,
        title: `Sadarbība`,
        sub: [
            {
                key: `online-tournaments`,
                title: `Tiešsaistes turnīru organizēšana`,
            },
            {
                key: `live-tournaments`,
                title: `Klātienes turnīru organizēšana`,
            },
            {
                key: `addvertisement`,
                title: `Reklāmas noteikumi`,
            },
            {
                key: `website-statistics`,
                title: `Mājas lapas statistika`,
            },
        ],
    },
];

export default footer;