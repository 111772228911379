import React, { Component } from 'react';

import './App.css';
import './design.css';

// import Client, { STATE } from './engine/client';
import { Modal, notification, message } from 'antd';
import Login from './engine/layout/Login';

import PageNotFound from './engine/layout/PageNotFound';
import routes from './engine/routes';
import Cookies from "js-cookie";
import Echo from "laravel-echo"

import ReactGA from 'react-ga';
import LeagueWrapper from './pages/League';

export const required = { required: true, message: 'Šis lauks ir obligāts.' };
export const AppContext = React.createContext();

class App extends Component {
	constructor(props) {
		super(props);

		this.echo = null;
		this.pusher = require('pusher-js');
		this.refreshInterval = null;

		this.state = this.default();
	}

	default = () => ({
		client: {
			status: `NONE`,
			route: {
				component: PageNotFound,
				path: window.location.pathname,
				results: [],
				props: {},
			},
			host: window.location.hostname === `localhost` ? `http://localhost:5000` : ``,
			window: {
				layout: document.body.offsetWidth > 1380 ? `desktop` : `mobile`,
				title: `Zolmaņiem | Mājas`,
				size: {
					width: document.body.offsetWidth,
					height: document.body.offsetHeight,
				}
			}
		},
		auth: {
			token: Cookies.get(`Authorization`),
			secret: null,
		},
		user: {
			id: null,
			lobby: null,
			name: `Nezināms`,
			surname: `Viesis`,
			dailyBonus: 0,
			points: 0,
			zd: 0,
			roles: [],
			achievements: [],
			notifications: [],
			statistics: {
				totaPlayedZoles: 0,
				totalPlayedSmallZoles: 0,
				totalWonSmallZoles: 0,
				totalWonZoles: 0,
				tourneyPoints: 0,
			}
		},
		websock: {
			ready: false,
		}
	});

	fn = ({
		sdk: {
			min: (a, b) => a > b ? b : a,
			max: (a, b) => a < b ? b : a,
		},
		client: {
			update: (status, cb = (s) => console.log(`Client state change: ${s}`)) => {
				cb(status);
				this.setState((old) => ({ ...old, client: { ...old.client, status } }));
			},
			navigate: (src, title = null) => {
				const { user } = this.state;
				document.title = title ?? this.state.client.window.title ?? `Zolmaņiem | Mājas`;
				window.history.pushState({ urlPath: src }, document.title, src);

				const newRoute = {
					component: PageNotFound,
					path: src,
					results: [],
					props: {},
				};
		
				for (const route of routes) {
					newRoute.results = route.path.exec(newRoute.path);
					if (newRoute.results) {
						if (!(`can` in route) || route.can(user)) {
							newRoute.component = route.component;
						} else {
							newRoute.component = PageNotFound;
							newRoute.props = { title: `403 Nav piekļuves`, subTitle: `Tev nav piekļuves šim saturam.` };
						}
		
						break;
					}
				}

				this.setState(
					(old) => ({
						...old,
						client: {
							...old.client,
							route: { ...newRoute },
							window: {
								...old.client.window,
								title: document.title
							}
						}
					}),
					() => ReactGA.pageview(src + window.location.search)
				);
			},
			reload: () => {
				const { user } = this.state;
				
				const newRoute = {
					component: PageNotFound,
					path: window.location.pathname,
					results: [],
					props: {},
				};
		
				for (const route of routes) {
					newRoute.results = route.path.exec(newRoute.path);
					if (newRoute.results) {
						if (!(`can` in route) || route.can(user)) {
							newRoute.component = route.component;
						} else {
							newRoute.component = PageNotFound;
							newRoute.props = { title: `403 Nav piekļuves`, subTitle: `Tev nav piekļuves šim saturam.` };
						}
		
						break;
					}
				}
				
				this.setState(
					(old) => ({
						...this.default(),
						client: {
							...this.default().client,
							route: { ...newRoute },
						}
					})
				);
			},
		},
		api: {
			get: (src, page = 1, size = 10, e = [], h = {}, v = `v1`) => {
				const { client, auth } = this.state;

				if (size) e.unshift({ key: `size`, value: size });
				if (page) e.unshift({ key: `page`, value: page });
			
				return new Promise((r) => {
					fetch(
						`${client.host}/api/${v}/${src}${e.length ? `?` : ``}${e.map((p) => `${p.key}=${p.value}`).join(`&`)}`,
						{
							headers: {
								Accept: `application/json`,
								"Content-Type": `application/json`,
								Authorization: auth.token ?? null,
								...h,
							},
						}
					)
						.then(async (data) => {
							const response = { ok: data.ok, status: data.status };
							try {
								const _data = await data.json();
								r({ ...response, body: _data });
							} catch (err) {
								// console.error(`Failed to parse JSON.`);
								r({ ...response, body: { raw: data } });
							}
						})
						.catch((err) => console.error(err));
				});
			},		
			post: (route, item, h = {}, v = `v1`, json = true) => {
				const { client, auth } = this.state;

				return new Promise((r) => {
					fetch(`${client.host}/api/${v}/${route}`, {
						method: `POST`,
						headers: {
							Accept: `application/json`,
							...(json ? {"Content-Type": `application/json`} : {}),
							Authorization: auth.token ?? null,
							...h,
						},
						body: json ? JSON.stringify(item ?? {}) : item,
					})
						.then(async (data) => {
							const response = { ok: data.ok, status: data.status };
							const _data = await data.json();

							r({ ...response, body: _data });
						})
						.then((data) => r(data))
						.catch((err) => console.error(err));
				});
			},		
			patch: (route, id, item, h = {}, v = `v1`) => {
				const { client, auth } = this.state;

				return new Promise((r) => {
					fetch(`${client.host}/api/${v}/${route}/${id}`, {
						method: `PATCH`,
						headers: {
							Accept: `application/json`,
							"Content-Type": `application/json`,
							Authorization: auth.token ?? null,
							...h,
						},
						body: JSON.stringify(item ?? {}),
					})
						.then(async (data) => {
							const response = { ok: data.ok, status: data.status };
							const _data = await data.json();

							r({ ...response, body: _data });
						})
						.then((data) => r(data))
						.catch((err) => console.error(err));
				});
			},		
			delete: (route, id, h = {}, v = `v1`) => {
				const { client, auth } = this.state;

				return new Promise((r) => {
					fetch(`${client.host}/api/${v}/${route}/${id}`, {
						method: `DELETE`,
						headers: {
							Accept: `application/json`,
							"Content-Type": `application/json`,
							Authorization: auth.token ?? null,
							...h,
						},
					})
						.then(async (data) => {
							const response = { ok: data.ok, status: data.status };
							const _data = await data.json();

							r({ ...response, body: _data });
						})
						.then((data) => r(data))
						.catch((err) => console.error(err));
				});
			}
		},
		user: {
			refresh: () => {
				const { auth } = this.state;
				const { api } = this.fn;

				if (auth.token) {
					api.get(`me`, null, null, [], {}, `auth`)
						.then((response) => {
							if (response.ok && response.status === 200) {
								this.setState((old) => ({
									...old,
									user: {
										...old.user,
										...response.body
									}
								}));
							}
						});

					api.post(`refresh`, null, null, `auth`)
						.then((response) => {
							if (response.ok && response.status === 200) {
								this.setState((old) => ({
									...old,
									auth: {
										...old.auth,
										token: response.body.access_token ? `Bearer ${response.body.access_token}` : Cookies.get(`Authorization`) ?? null
									}
								}));
							}
						});
				}
			},
			authorize: (
				cb = () => console.log(`Authorization completed succesfully.`),
				fb = (code) => console.warn(`Authorization failed with code "${code}"`)
			) => {
				const { auth } = this.state;
				const { api, client } = this.fn;
				
				client.update(`AUTH`);
				if (auth.token) {
					api.get(`me`, null, null, [], {}, `auth`)
						.then((response) => {
							if (response.ok && response.status === 200) {
								this.setState((old) => ({
									...old,
									client: {
										...old.client,
										status: `AUTH-SUCCESS`
									},
									user: {
										...old.user,
										...response.body
									},
									auth: {
										...old.auth,
										secret: null,
									},
								}));

								cb();
							} else {
								Cookies.remove(`Authorization`);
								this.setState((old) => ({
									...old,
									client: {
										...old.client,
										status: `AUTH-FAILED`
									},
									auth: {
										token: null,
										secret: null
									},
								}));

								message.destroy();
								notification.destroy();
								notification.error({
									duration: 60,
									message: `Tava sesija ir beigusies`,
									description: `Lūdzu autorizējies vēlreiz.`,
								});

								fb(`ERR-${response.status}`);
							}
						});
				} else {
					this.setState((old) => ({
						...old,
						client: {
							...old.client,
							status: `AUTH-FAILED`
						},
						auth: {
							token: null,
							secret: null
						},
					}));

					fb(`ERR-EMPTY-TOKEN`);
				}
			},
			register: (name, surname, email) => {
				const { api } = this.fn;

				const data = { name, surname, email };
				api.post(`register`, data, null, `auth`)
					.then((response) => {
						if (response.ok && response.status >= 200 && response.status <= 300) {
							message.success(response.body?.message ?? `Nosūtījām uz tavu e-pastu linku ar norādījumiem reģistrācijas pabeigšanai.`);
						} else {
							message.destroy();
							notification.destroy();
							notification.error({
							  duration: 60,
							  message: response.body.title ?? `Mēģiniet vēlreiz!`,
							  description: response.body.error ?? `Kaut kas nogāja greizi - neizdevās reģistrēties.`,
							});
						}
					});
			},
			login: (email, password, remember) => {
				const { auth } = this.state;
				const { api } = this.fn;
				
				if (auth.token) {
					message.destroy();
					notification.destroy();

					message.info(`Lietotājs jau ir autorizējies`);
					return;
				}

				const data = { email, password, remember };
				api.post(`login`, data, null, `auth`)
					.then((response) => {
						if (response.ok && response.status === 200) {
							this.setState((old) => ({
								...old,
								client: {
									...old.client,
									status: `AUTH-SUCCESS`
								},
								auth: {
									secret: null,
									token: response.body.access_token ? `Bearer ${response.body.access_token}` : Cookies.get(`Authorization`) ?? null,
								},
							}));

							api.get(`me`, null, null, [], {}, `auth`)
								.then((response) => {
									if (response.ok && response.status === 200) {
										this.setState((old) => ({
											...old,
											user: {
												...old.user,
												...response.body
											}
										}));
									}
								});
						} else {
							Cookies.remove(`Authorization`);
							this.setState((old) => ({
								...old,
								client: {
									...old.client,
									status: `AUTH-FAILED`
								},
								auth: {
									token: null,
									secret: null
								},
							}));
							
							message.destroy();
							notification.destroy();

							notification.error({
								duration: 60,
								message: response.body.title ?? `Mēģiniet vēlreiz!`,
								description: response.body.error ?? `Kaut kas nogāja greizi - neizdevās autorizēties.`,
							});
						}
					});
			},
			logout: () => {
				const { auth } = this.state;
				const { client, api } = this.fn;
				
				if (auth.token) {
					api.post(`logout`, null, null, `auth`)
						.then((response) => {
							if (response.ok && response.status === 200) {
								message.destroy();
								notification.destroy();
								message.success(`Izrakstīšanās veiksmīga.`);

								Cookies.remove(`Authorization`);
								client.reload();
							}
						});
				}
			},
			updateLobby: (id) => {
				this.setState((old) => ({
					...old,
					user: {
						...old.user,
						lobby: id,
					}
				}));
			}
		},
		websock: {
			authorize: () => {
				const { api, websock: ws } = this.fn;
				const { websock } = this.state;

				if (!websock.ready) {
					return;
				}

				api.get(`ws`, null, null, [{ key: `socket`, value: this.echo.socketId() }], {}, `auth`)
					.then((response) => {
						if (response.ok && response.status === 200) {
							if (response.body.secret) {
								this.setState((old) => ({
									...old,
									auth: {
										...old.auth,
										secret: response.body.secret
									}
								}));

								ws.subscribe( `auth`, (c) => c.whisper(`pusher:auth`, { secret: response.body.secret }));
							}
						}
					});
			},
			connect: (
				cb = () => console.log(`Websocket connected.`),
				fb = (state) => console.warn(`Websocket failed to connect: "${state}".`)
			) => {
				if (!this.echo) {
					this.echo = new Echo({
						broadcaster: `pusher`,
						key: process.env.REACT_APP_PUSHER_KEY,
						wsHost: window.location.hostname,
						wsPort: 6001,
						enabledTransports: [`ws`],
						forceTLS: false,
					});
		
					this.echo.connector.pusher.connection.bind(`state_change`, (state) => {
						switch (state.current.toLowerCase()) {
							case `connected`:
								this.setState((old) => ({
									...old,
									client: {
										...old.client,
										status: `WEBSOCK-${state.current.toUpperCase()}`
									},
									websock: {
										ready: true
									}
								}));
								cb();
								break;
							
							default:
								this.setState((old) => ({
									...old,
									client: {
										...old.client,
										status: `WEBSOCK-${state.current.toUpperCase()}`
									},
									websock: {
										ready: false
									}
								}));
								fb();
								break;
						}
					});
				}
		
				return this;
			},
			subscribe: (channel, cb = () => {}) => {
				const { websock } = this.state;

				if (websock.ready) {
					const ret = this.echo.channel(channel);
					ret.on(`auth.failed`, () => {
						this.setState((old) => ({
							...old,
							client: {
								...old.client,
								status: `WEBSOCK-AUTH-FAILED`
							},
							auth: {
								...old.auth,
								secret: null
							},
							websock: {
								ready: false
							}
						}));
					});
					ret.on(`auth.success`, (e) => {
						this.setState((old) => ({
							...old,
							client: {
								...old.client,
								status: `WEBSOCK-AUTH-SUCCESS`
							}
						}));
					});
					ret.whisper = function (name, data) {
						// console.log(`whisper`, name, data);
						this.pusher.send_event(name, data, channel);
					}
					ret.subscribed(async () => {
						console.log(`Joined channel: ${channel}"`)
						cb(ret);
					});
		
					return ret;
				} else {
					console.log(`Tried to subscribe to channel "${channel}", but websocket isn't ready yet.`);
				}
		
				return false;
			},
			unsubscribe: (channel, cb = (c) => console.log(`Unsubscribed from ${c}`)) => {
				const { websock } = this.state;

				if (websock.ready) {
					this.echo.leaveChannel(channel);
					cb(channel);
				}
			},
			disconnect: () => {
				const { websock } = this.state;

				if (websock.ready) {
					try {
						this.echo?.connector?.pusher?.disconnect();
					} catch (ex) {
						console.error(ex);
					}
					
					this.echo = null;
					this.setState((old) => ({
						...old,
						client: {
							...old.client,
							status: `WEBSOCK-DISCONNECTED`
						},
						websock: {
							ready: false
						}
					}));
				}
			},
		},
	});

	componentDidMount() {
		const { user } = this.state;

		const newRoute = {
			component: PageNotFound,
			path: window.location.pathname,
			results: [],
			props: {},
		};

		for (const route of routes) {
			newRoute.results = route.path.exec(newRoute.path);
			if (newRoute.results) {
				if (!(`can` in route) || route.can(user)) {
					newRoute.component = route.component;
				} else {
					newRoute.component = PageNotFound;
					newRoute.props = { title: `403 Nav piekļuves`, subTitle: `Tev nav piekļuves šim saturam.` };
				}

				break;
			}
		}

		this.setState(
			(old) => ({
				...old,
				client: {
					...old.client,
					route: { ...newRoute }
				}
			})
		);

		window.onpopstate = () => {
			const newRoute = {
				component: PageNotFound,
				path: window.location.pathname,
				results: [],
				props: {},
			};

			for (const route of routes) {
				newRoute.results = route.path.exec(newRoute.path);
				if (newRoute.results) {
					if (!(`can` in route) || route.can(user)) {
						newRoute.component = route.component;
					} else {
						newRoute.component = PageNotFound;
						newRoute.props = { title: `403 Nav piekļuves`, subTitle: `Tev nav piekļuves šim saturam.` };
					}

					break;
				}
			}

			this.setState(
				(old) => ({
					...old,
					client: {
						...old.client,
						route: { ...newRoute }
					}
				}),
				() => ReactGA.pageview(window.location.pathname + window.location.search)
			);
		};

		window.onresize = () => {
			this.setState((old) => ({
				...old,
				client: {
					...old.client,
					window: {
						...old.client.window,
						layout: document.body.offsetWidth > 1380 ? `desktop` : `mobile`,
						size: {
							width: document.body.offsetWidth,
							height: document.body.offsetHeight,
						},
					}
				}
			}));
		};

		console.log(`Initalization started.`);
		this.fn.user.authorize();
	}

	componentDidUpdate(prevProps, prevState) {
		const { status } = this.state.client;
		const { websock, client, user } = this.fn;

		if (status !== prevState.client.status) {
			switch(status) {
				case `NONE`:
					console.log(`Initalization started.`);
					
					if (this.refreshInterval) {
						clearInterval(this.refreshInterval);
						this.refreshInterval = null;
					}
					user.authorize();
					break;

				case `AUTH-SUCCESS`:
					console.log(`Connecting websocket.`);
					websock.connect();
					break;

				case `WEBSOCK-CONNECTED`:
					console.log(`Authorizing websocket.`);
					websock.authorize();
					break;

				case `WEBSOCK-AUTH-SUCCESS`:
					console.log(`Websocket authorized.`);
					message.destroy();
					notification.destroy();
					
					message.success(`Autorizācija veiksmīga.`);

					client.update(`READY`);
					break;

				case `READY`:
					this.refreshInterval = setInterval(() => user.refresh(), 5 * 60 * 1000);
					console.log(`Initalization finished.`);
					break;

				default:
					console.log(`Unknown client status (${status}) detected.`);
					break;
			}
		}
	};

	render() {
		const { client } = this.state;
		const { route } = client;

		return (
			<>
				<AppContext.Provider
					value={{
						state: { ...this.state },
						fn: { ...this.fn }
					}}
				>
					<Modal
						centered
						closable={false}
						zIndex={999999}
						width="85vw"
						visible={client.status === `AUTH-FAILED`}
						footer={null}
						bodyStyle={{
							position: `relative`,
							minHeight: 725,
							background: `white`,
						}}
						destroyOnClose
					>
						<Login app={{ state: { ...this.state }, fn: { ...this.fn }  }} />
					</Modal>
					<route.component {...route.props} />
					{/* <LeagueWrapper {...route.props} /> */}
				</AppContext.Provider>
			</>
		);
	}
}

export default App;
