import React, { Component } from 'react';
import { Dropdown, Layout, Menu, Typography } from 'antd';

import menu from '../menu';

import menuLogo from '../../resources/menu.svg';
import facebook from '../../resources/facebook.svg';
import logo from "../../resources/logo.png"
import chat from "../../resources/chat.svg"
import settings from "../../resources/settings.svg"
import profile from "../../resources/profile.svg"
import footer from '../footer';
import Points, { ptStr } from '../widgets/Points';

// import LayoutMobile from './LayoutMobile';
// import LayoutWide from './LayoutWide';
// import { AppContext } from '../App';

const { Header, Footer, Content } = Layout;

const pt = (x, y) => ({ x, y });
const linear = (from, to) => {
    const a = (to.y - from.y) / (to.x - from.x);
    const b = from.y - a * from.x;

    return { fn: (x) => (a * x + b) };
};

// const Layout = (props) => {
//     const app = useContext(AppContext);

//     return <PageLayout app={app} {...props} />;
// }

const calcTextSize = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    context.font = font || getComputedStyle(document.body).font;
  
    return context.measureText(text);
}

class PageLayout extends Component {
    // mobileHeadVh = 25;
    // mobileHeadHeight = 300;
    
    layout = () => ({
        content: {
            size: (layout = `desktop`) => {
                const { window } = this.app.state.client;

                let width = window.size.width;
                if (layout === `desktop`) {
                    width = window.size.width * 0.7;
                    if (width < 1300) {
                        width = 1300;
                    }

                    if (width >= window.size.width - 100) {
                        width = window.size.width - 100;
                    }
                }

                const margin = (window.size.width - width) / 2;

                return {
                    width,
                    margin
                };
            },
            render: ({ width, margin, layout = `desktop` }) => {
                return (
                    <Layout>
                        <Content className="main-content">
                            <div className={`main-content-box-${layout}`} style={{ width, margin }/**: `75px auto` */}> 
                                {this.content()}
                            </div>
                        </Content>
                    </Layout>
                );
            }
        },
        header: {
            logo: {
                render: ({ height, left, top, width }) => {
                    return (   
                        <img
                            src={logo}
                            alt="Zolmaniem"
                            className="logo"
                            style={{ height, left, top, width }}
                        />
                    );
                },
                size: (
                    height = { min: 55, max: 100, current: 100 },
                    cfg = { height: { min: 40, max: 56 }, left: { min: 20, max: 69 }, top: { min: 5, max: 22 } }
                ) => ({
                    height: linear(pt(height.min, cfg.height.min), pt(height.max, cfg.height.max)).fn(height.current),
                    left: linear(pt(height.min, cfg.left.min), pt(height.max, cfg.left.max)).fn(height.current),
                    top: linear(pt(height.min, cfg.top.min), pt(height.max, cfg.top.max)).fn(height.current),
                    width: linear(pt(height.min, cfg.height.min), pt(height.max, cfg.height.max)).fn(height.current) * 3.25,
                })
            },
            menu: {
                render: (layout, cfg = { left: 300, font: 16, margin: 10, width: 0, top: 60 }) => {
                    const { client, user } = this.props.app.state;
                    const { navigate } = this.props.app.fn.client;

                    if (cfg.width < 0) {
                        return <></>;
                    }
                    
                    if (layout === `mobile`) {
                        return <></>;
                    }

                    const admin = [];
                    if (user.roles.includes(`Administrators`)) {
                        admin.push({ key: `admin`, title: `Admin` });
                    }

                    const items = [ ...menu, ...admin ];
                    return (
                        <div className="menu-list-desktop" style={{ left: cfg.left, width: cfg.width }}>
                            {
                                items.map(i => ({ ...i, active: client.route.path === `/${i.key}` })).map((item) => (
                                    <Typography.Link
                                        className={`menu-link${item.active ? `-active` : ``}`}
                                        onClick={() => navigate(`/${item.key}`, `Zolmaniem | ${item.title}`)}
                                        style={{
                                            fontSize: cfg.font,
                                            marginLeft: `${cfg.margin}px`,
                                            marginRight: `${cfg.margin}px`,
                                            paddingTop: cfg.top,
                                        }}
                                    >
                                        {item.title}
                                    </Typography.Link>
                                ))
                            }
                        </div>
                    );
                },
                size: (
                    width,
                    height = { min: 55, max: 100, current: 100 },
                    cfg = { font: { min: 14, max: 16 }, margin: { min: 5, max: 10 }, top: { min: 20, max: 60 } }
                ) => {
                    const { user } = this.props.app.state;

                    const calcMenuWidth = (menu, font, margin) => {
                        const { client } = this.props.app.state;
                        let result = 0;
    
                        for (const item of menu) {
                            const active = client.route.path === `/${item.key}`;
                            result += calcTextSize(item.title.toUpperCase(), `${active ? `bold ` : ``}${font}px Roboto`).width;
                            result += margin * 2;
                        }
    
                        return result;
                    };
    
                    const admin = [];
                    if (user.roles.includes(`Administrators`)) {
                        admin.push({ key: `admin`, title: `Admin` });
                    }
                    const items = [ ...menu, ...admin ];
                    
                    let font = cfg.font.max;
                    let margin = cfg.margin.max;
    
                    const top = linear(pt(height.min, cfg.top.min), pt(height.max, cfg.top.max)).fn(height.current);
                    do {
                        const result = calcMenuWidth(items, font, margin);
                        if (result <= width) {
                            return { width: result, font, margin, top };
                        }
    
                        if (margin > cfg.margin.min) {
                            margin--;
                        } else if (font > cfg.font.min) {
                            font--;
                        } else {
                            // console.log(result, width, -(result - width));
                            return { width: -(result - width), font, margin, top };
                        }
    
                    } while (margin >= cfg.margin.min || font >= cfg.font.min);
    
                    return false;
                }
            },
            nav: {
                icons: {
                    user: {
                        size: (height) => {
                            const { user } = this.props.app.state;
                            const { max } = this.props.app.fn.sdk;

                            const h = linear(pt(height.min, 28), pt(height.max, 33)).fn(height.current);
                            const p = linear(pt(height.min, 15), pt(height.max, 20)).fn(height.current);
                            const t = linear(pt(height.min, 8), pt(height.max, 41)).fn(height.current);

                            const tn = linear(pt(height.min, 12), pt(height.max, 49)).fn(height.current);
                            const tp = linear(pt(height.min, 30), pt(height.max, 69)).fn(height.current);

                            const bn = calcTextSize(`${user.name} ${user.surname}`, `bold 14px Roboto`);
                            const bp = calcTextSize(ptStr(user.zd), `14px Roboto`);

                            return {
                                top: t,
                                padding: p / 2,
                                icon: {
                                    height: h,
                                    width: h + 3,
                                    right: max(bn.width, 80),
                                },
                                name: {
                                    height: 19,
                                    width: max(bn.width, 80),
                                    right: 0,
                                    top: tn,
                                },
                                points: {
                                    height: 19,
                                    width:  bp.width,
                                    right: max(bn.width, 80) - bp.width,
                                    top: tp,
                                },
                                box: {
                                    width: h + 3 + p + max(bn.width, 80),
                                    height: height.current,
                                },
                            };
                        },
                        render: ({right, top, padding, icon, name, points, box}) => {
                            const { user } = this.props.app.state;
                            
                            return (
                                <div className="nav-icons">
                                    <img
                                        src={profile}
                                        Alt="profile"
                                        className="nav-icon"
                                        style={{
                                            top,
                                            padding,
                                            ...icon,
                                            right: right + icon.right,
                                        }}
                                    />
                                    <span style={{ position: `absolute`, fontWeight: `bold`, ...name, right }}>{`${user.name} ${user.surname}`}</span>
                                    <Points
                                        points={user.zd}
                                        style={{ position: `absolute`, ...points, right: right + points.right }}
                                    />
                                </div>
                            );
                        }
                    },
                    settings: {
                        size: (height) => {
                            const h = linear(pt(height.min, 28), pt(height.max, 33)).fn(height.current);
                            const p = linear(pt(height.min, 15), pt(height.max, 20)).fn(height.current);
                            const t = linear(pt(height.min, 8), pt(height.max, 41)).fn(height.current);

                            return {
                                top: t,
                                height: h,
                                width: h + 3,
                                padding: p / 2,
                                box: {
                                    width: h + 3 + p,
                                    height: height.current,
                                }
                            };
                        },
                        render: ({right, top, height, width, padding, box, ...style}) => (
                            <Dropdown
                                trigger={[`click`]}
                                overlay={(
                                    <Menu>
                                        <Menu.Item key="0">Iestatījumi</Menu.Item>
                                        <Menu.Item key="1" onClick={this.props.app.fn.user.logout}>Iziet</Menu.Item>
                                    </Menu>
                                )}
                            >
                                <img
                                    src={settings}
                                    Alt="settings"
                                    className="nav-icon"
                                    style={{
                                        top,
                                        right,
                                        height,
                                        width,
                                        padding,
                                        ...style
                                    }}
                                />
                            </Dropdown>
                        )
                    },
                    chat: {
                        size: (height) => {
                            const h = linear(pt(height.min, 29), pt(height.max, 33)).fn(height.current);
                            const p = linear(pt(height.min, 15), pt(height.max, 20)).fn(height.current);
                            const t = linear(pt(height.min, 8), pt(height.max, 41)).fn(height.current);

                            return {
                                top: t,
                                height: h,
                                width: h + 3,
                                padding: p / 2,
                                box: {
                                    width: h + 3 + p,
                                    height: height.current,
                                }
                            };
                        },
                        render: ({right, top, height, width, padding, box, ...style}) => (
                            <img
                                src={chat}
                                Alt="chat"
                                className="nav-icon"
                                style={{
                                    top,
                                    right,
                                    height,
                                    width,
                                    padding,
                                    ...style
                                }}
                            />
                        )
                    },
                    menu: {
                        size: () => {
                            // const h = linear(pt(height.min, 22), pt(height.max, 33)).fn(height.current);
                            // const p = linear(pt(height.min, 15), pt(height.max, 20)).fn(height.current);

                            // return {
                            //     top: (height.current - h) / 2,
                            //     height: h,
                            //     width: h + 3,
                            //     padding: p / 2,
                            //     box: {
                            //         width: h + 3 + p,
                            //         height: height.current,
                            //     }
                            // };
                        },
                        render: ({right, top, height, width, padding, box, ...style}) => (
                            <img
                                src={menuLogo}
                                alt="menu"
                                style={{
                                    top,
                                    right,
                                    height,
                                    width,
                                    padding,
                                    ...style
                                }}
                            />
                        )
                    }
                },
                render: (
                    collapsed = false,
                    box = { width: 0, right: 10, height: 100 },
                    height = { min: 55, max: 100, current: 100 }
                ) => {
                    const icons = [
                        this.layout().header.nav.icons.user,
                        this.layout().header.nav.icons.settings,
                        this.layout().header.nav.icons.chat,
                    ];

                    if (collapsed) {
                        icons.push(this.layout().header.nav.icons.menu);
                    }

                    let pad = 0;
                    return (
                        <div className="nav-box" style={{ ...box }}>
                            {
                                icons.reverse().map((icon) => {
                                    const iconSize = icon.size(height);
                                    const res = icon.render({ ...iconSize, right: pad });

                                    pad += iconSize.box.width;
                                    return res;
                                })
                            }
                        </div>
                    );
                },
                size: (collapsed = false, height = { min: 55, max: 100, current: 100 }) => {
                    const contentSize = this.layout().content.size();
                    const icons = [
                        this.layout(this.props.app).header.nav.icons.user,
                        this.layout(this.props.app).header.nav.icons.settings,
                        this.layout(this.props.app).header.nav.icons.chat,
                    ];

                    if (collapsed) {
                        icons.push(this.layout(this.props.app).header.nav.icons.menu);
                    }

                    const box = {
                        width: 0,
                        right: contentSize.margin,
                        height: height.current
                    };

                    for (const icon of icons) {
                        box.width += icon.size(height).box.width;
                    }

                    return { ...box };
                },
            },
            size: () => {
                const { window } = this.app.state.client;
                const height = { min: 55, max: 100, current: 100 };

                let layout = `mobile`;
                while (height.current >= height.min) {
                    const logoSize = this.layout(this.props.app).header.logo.size(height);
                    const logoBoxWidth = logoSize.width + logoSize.left + (height.current / 2);

                    const navSize = this.layout(this.props.app).header.nav.size(false, { ...height });
                    const navBoxWidth = navSize.width + navSize.right;

                    const menuSize = this.layout(this.props.app).header.menu.size(window.size.width - logoBoxWidth - navBoxWidth);

                    if (menuSize.width > 0) {
                        layout = `desktop`;
                        break;
                    }

                    height.current--;
                }

                if (height.current < height.min) {
                    height.current = height.min;
                }

                return { layout, height: height.current };
            },
            render: () => {
                const { window } = this.app.state.client;
                const { height, layout } = this.layout(this.props.app).header.size();
                
                const logoSize = this.layout(this.props.app).header.logo.size({ min: 55, max: 100, current: height });
                const logoBoxWidth = logoSize.width + logoSize.left + (height / 2);

                const navSize = this.layout(this.props.app).header.nav.size(false, { min: 55, max: 100, current: height });
                const navBoxWidth = navSize.width + navSize.right;

                const menuSize = this.layout(this.props.app).header.menu.size(window.size.width - logoBoxWidth - navBoxWidth, { min: 55, max: 100, current: height },);

                return (
                    <Header style={{ height }}>
                        <div
                            className="header-parent"
                            style={{ height }}
                        >
                            {this.layout(this.props.app).header.logo.render(logoSize)}
                            {this.layout(this.props.app).header.menu.render(layout, { ...menuSize, left: logoBoxWidth, width: window.size.width - logoBoxWidth - navBoxWidth })}
                            {this.layout(this.props.app).header.nav.render(false, navSize, { min: 55, max: 100, current: height })}
                        </div>
                    </Header>
                );
            },
        },
        footer: {
            render: () => (
                <Footer>
                    <div className="footer-box-desktop">
                        {
                            footer.map((item) => {
                                return (
                                    <Typography.Link
                                        key={item.key}
                                        className="footer-box-link"
                                        onClick={() => {
                                            if (`onClick` in item) {
                                                item.onClick();
                                            } else if (item.type === `link` && `href` in item) {
                                                this.props.navigate(item.href);
                                            }
                                        }}
                                        style={{ ...(`style` in item ? item.style : {}) }}
                                    >
                                        {item.title}
                                        {!!(`icon` in item) && item.icon}
                                    </Typography.Link>
                                );
                            })
                        }
                        <a href="https://www.facebook.com/zolmaniem.lv" target="_blank" rel="noreferrer">
                            <img src={facebook} alt="facebook" />
                        </a>
                        <br />
                        <span className="copyright-desktop">
                            Zolmaniem © 2024 Visas tiesības aizsargātas
                        </span>
                    </div>
                </Footer>
            ),
        }
    });

    content() {
        return (
            <h2>Zolmaņiem</h2>
        );
    }

    head() {
        return <></>;
    }

    render() {
        // const navSize = this.layout().nav.size(`desktop`);
        
        // console.log(this.app);
        const contentSize = this.layout().content.size(`desktop`);

        return (
            <Layout>
                {this.layout().header.render()}
                {this.layout().content.render(contentSize)}
                {/* {this.layout().footer.render()} */}
            </Layout>
        );

        // if (this.props.size > 1380) {
        //     return <LayoutWide {...this.props}>{this.content()}</LayoutWide>
        // }

        // return <LayoutMobile {...this.props} headVh={this.mobileHeadVh} headHeight={this.mobileHeadHeight} head={this.head()}>{this.content()}</LayoutMobile>;
    }
}

export default PageLayout;
